"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import ThemeWrapper from "./common/theme";
import { store } from "./store";
import { lazyWithRetry } from "./utils/lazy-retry";
import router from "./routes";
import { Toast } from "./administration/components/toast/Toast";
import { ImpersonatedToast } from "./administration/components/impersonateToast/ImpersonateToast";
import { LoginProvider } from "./common/pages/login/login-context";
import { ToastProvider } from "./administration/common/components/toast/ToastPovider";
import { initializeMS } from "./common/pages/login/ms-services";
import { KeyBoardHotKeysProvider } from "./common/context/hotkeys.context";
const Modal = lazyWithRetry(() => import("./common/components/modal"));
import "./App.css";
function App() {
  useEffect(() => {
    initializeMS();
  }, []);
  return /* @__PURE__ */ jsx(ToastProvider, { children: /* @__PURE__ */ jsx(LoginProvider, { children: /* @__PURE__ */ jsx(Provider, { store, children: /* @__PURE__ */ jsx(KeyBoardHotKeysProvider, { children: /* @__PURE__ */ jsxs(ThemeWrapper, { children: [
    /* @__PURE__ */ jsx(RouterProvider, { router }),
    /* @__PURE__ */ jsx(Modal, {}),
    /* @__PURE__ */ jsx(Toast, {}),
    /* @__PURE__ */ jsx(ImpersonatedToast, {})
  ] }) }) }) }) });
}
export default App;

"use strict";
import { jsx } from "react/jsx-runtime";
import { ProtectedRoute } from "../common/components/authentication/protected";
import { PermittedRoute } from "../common/components/permissions/permittedRoute";
import Loadable from "../common/components/loadable";
import { lazyWithRetry } from "../utils/lazy-retry";
const Suggestions = Loadable(
  lazyWithRetry(() => import("../common/pages/suggestions/suggestions"))
);
const PersonalSettings = Loadable(lazyWithRetry(() => import("../common/pages/settings/personal")));
const UserLayout = Loadable(lazyWithRetry(() => import("../common/components/layout/user-layout")));
const DismissPage = Loadable(lazyWithRetry(() => import("../common/pages/dismiss-page")));
export const UserRoutes = {
  path: "/",
  element: /* @__PURE__ */ jsx(ProtectedRoute, { children: /* @__PURE__ */ jsx(PermittedRoute, { accessRules: { roles: ["PUBLIC"] }, children: /* @__PURE__ */ jsx(UserLayout, {}) }) }),
  children: [
    {
      path: "/",
      element: /* @__PURE__ */ jsx(Suggestions, { mode: "web" })
    },
    {
      path: "/suggestions",
      element: /* @__PURE__ */ jsx(Suggestions, { mode: "web" })
    },
    {
      path: "/dismiss/:surveyId",
      element: /* @__PURE__ */ jsx(DismissPage, { mode: "web" })
    },
    {
      path: "settings",
      element: /* @__PURE__ */ jsx(PersonalSettings, { mode: "web" })
    }
  ]
};

import React, { useCallback, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { usePermissions } from '../../hooks/usePermissions';
import { IAccessRules } from '../../interfaces/IAcl';

export const PermittedRoute = ({
  children,
  accessRules,
}: {
  children: React.ReactNode;
  accessRules?: IAccessRules;
}) => {
  const [isAllowed, setIsAllowed] = useState<boolean | null>(null);
  const { rules } = usePermissions();
  const location = useLocation();

  const checkPermissions = useCallback(() => {

    if (!accessRules) {
      setIsAllowed(false);
      return;
    }

    const isPublic = accessRules.roles.includes('PUBLIC');
    const hasRole = rules.roles.some((r: string) => accessRules.roles.includes(r));
    const isGlobalAdmin = rules.roles.includes('global_admin');

    setIsAllowed(isPublic || hasRole || isGlobalAdmin);
  }, [accessRules?.roles, rules.roles]);

  useEffect(() => {
    checkPermissions();
  }, [location.pathname, checkPermissions]);

  if (isAllowed === null) {
    return null;
  }

  if (isAllowed === false) {
    return <Navigate to="/" />;
  }

  return children;
};

"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { debounce } from "lodash";
import { openModal } from "@common/components/modal/modal.slice";
import { CONFIRMATION_ACTION_TYPE } from "@common/components/modal/constants";
export const debounceMiddleware = () => {
  return (next) => (action) => {
    var _a;
    if (!((_a = action.meta) == null ? void 0 : _a.delay)) {
      return next(action);
    }
    const debouncedAction = debounce(next, action.meta.delay);
    return debouncedAction(action);
  };
};
export const withDelay = (action, delay) => {
  return __spreadProps(__spreadValues({}, action), {
    meta: { delay }
  });
};
export const confirmationMiddleware = ({ dispatch }) => {
  return (next) => (action) => {
    if (action.type !== CONFIRMATION_ACTION_TYPE) {
      return next(action);
    }
    const { title, content, cancelFn, actionType, actionPayload, callbackFn } = action.meta.modalOptions;
    const confirmAction = { type: actionType, payload: actionPayload };
    dispatch(openModal({ title, content, confirmAction, cancelFn, callbackFn }));
  };
};

import { AxiosResponse } from 'axios';
import { config } from '../../../common/config/configurations';
import { instance } from '../../common/apis/baseApi';

export const getProviders = async ({
  source,
  usecase,
}: {
  source: string;
  usecase: string;
}): Promise<AxiosResponse> => {
  return instance.get(
    `${config.REACT_APP_API_VERSION}/providers/search?source=${source}&usecase=${usecase}`,
  );
};

// todo: change data type
export const postProvider = async (data: any): Promise<AxiosResponse> => {
  return instance.post(`${config.REACT_APP_API_VERSION}/providers`, data);
};

export const putProvider = async (providerId: string, data: any): Promise<AxiosResponse> => {
  return instance.put(`${config.REACT_APP_API_VERSION}/providers/${providerId}`, data);
};

export const deleteProvider = async (providerId: string): Promise<AxiosResponse> => {
  return instance.delete(`${config.REACT_APP_API_VERSION}/providers/${providerId}`);
};

export const testAPIConnection = async (source: string, data: any): Promise<AxiosResponse> => {
  return instance.post(`${config.REACT_APP_API_VERSION}/providers/${source}/connect/api`, data);
};

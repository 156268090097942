"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { lazy } from "react";
export const lazyWithRetry = (componentImport) => lazy(() => __async(void 0, null, function* () {
  const pageHasAlreadyBeenForceRefreshed = JSON.parse(
    window.localStorage.getItem("page-has-been-force-refreshed") || "false"
  );
  try {
    const component = yield componentImport();
    window.localStorage.setItem("page-has-been-force-refreshed", "false");
    return component;
  } catch (error) {
    if (!pageHasAlreadyBeenForceRefreshed) {
      window.localStorage.setItem("page-has-been-force-refreshed", "true");
      return window.location.reload();
    }
    throw error;
  }
}));

"use strict";
import { jsx } from "react/jsx-runtime";
import { lazyWithRetry } from "../utils/lazy-retry";
import Loadable from "../common/components/loadable";
import Layout from "../common/components/layout";
const Login = Loadable(lazyWithRetry(() => import("../common/pages/login/login")));
const ResetPassword = Loadable(lazyWithRetry(() => import("../common/pages/login/reset-password")));
const Signup = Loadable(lazyWithRetry(() => import("../common/pages/login/signup")));
const Error404 = Loadable(lazyWithRetry(() => import("../common/pages/errors/404")));
const MultiAccount = Loadable(lazyWithRetry(() => import("../common/pages/login/multi-account")));
const Config = Loadable(lazyWithRetry(() => import("../common/pages/config")));
const OverviewPage = Loadable(lazyWithRetry(() => import("../common/pages/overview-page")));
export const AuthRoutes = {
  path: "/",
  element: /* @__PURE__ */ jsx(Layout, {}),
  children: [
    {
      path: "login",
      element: /* @__PURE__ */ jsx(Login, { mode: "web", callback: () => ({}) })
    },
    {
      path: "logout",
      element: /* @__PURE__ */ jsx(Login, { mode: "web", callback: () => ({}) })
    },
    {
      path: "reset-password/:resetToken?",
      element: /* @__PURE__ */ jsx(ResetPassword, {})
    },
    {
      path: "signup",
      element: /* @__PURE__ */ jsx(Signup, { mode: "web", callback: () => ({}) })
    },
    {
      // ms-teams config
      path: "config",
      element: /* @__PURE__ */ jsx(Config, {})
    },
    {
      path: "overview",
      element: /* @__PURE__ */ jsx(OverviewPage, {})
    },
    {
      path: "my/accounts",
      element: /* @__PURE__ */ jsx(MultiAccount, {})
    },
    {
      path: "*",
      element: /* @__PURE__ */ jsx(Error404, {})
    }
  ]
};
